/* eslint-disable no-underscore-dangle */
import { AuthentificationService } from '@libs/auth/src/lib/authentification.service';
import { Router } from '@angular/router';
import { pagesToggleService } from '@pages/services/toggler.service';
import { RootLayout } from '@pages/layouts/root/root.component';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CustomLocalStorageService } from '@libs/services/src/lib/utilities/custom-local-storage.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { MarketTypeService } from '@libs/services/src';
import { ModalMarketTypeComponent } from '@libs/components/src/lib/modal-market-type/modal-market-type.component';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'apps/eros-abm/src/environments/environment';
import { AffairMarketType } from '@libs/enum/src';

@Component({
  selector: 'app-app-with-sidebar',
  templateUrl: './app-with-sidebar.component.html',
  styleUrls: ['./app-with-sidebar.component.scss'],
})
export class AppWithSidebarComponent extends RootLayout implements OnInit, OnDestroy {

  menuLinks = [];
  public userInfos;
  public selectedMarketsTypes;
  public marketsTypes = [];
  public marketsTypes$: Subscription;
  public isDestroyed$ = new Subject<void>();

  constructor(
    _toggler: pagesToggleService,
    _router: Router,
    public localSotrageService: CustomLocalStorageService,
    private authService: AuthentificationService,
    private modalService: NzModalService,
    private marketTypeService: MarketTypeService,
  ) {
    super(_toggler, _router, localSotrageService);
    this.initSubscriptions();
  }

  ngOnInit(): void {
    this.userInfos = this.authService.getUserInfos();
    this.marketTypeService.getAll()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(
        (marketsTypes) => {
          this.marketsTypes = marketsTypes;
        },
      );
    this._menuPin = this.localStorageService.isMenuPin();
    this.selectedMarketsTypes = this.localStorageService.getSelectedMarketsTypes();
    this.toggleMenuPin(null);
    this.initMenuLinks();
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }

  logout(): void {
    this.authService.logout();
  }

  onSelectMarketTypeClicked(): void {
    this.modalService.create({
      nzContent: ModalMarketTypeComponent,
      nzComponentParams: {
        marketsTypes: this.marketsTypes,
      },
    });
  }

  isAdministrator(): boolean {
    return this.authService.isAdministrator();
  }

  isManager(): boolean {
    return this.authService.isManager();
  }

  isProductionMode(): boolean {
    return environment.production;
  }

  haveRenovationMarket(): boolean {
    const found = this.selectedMarketsTypes.find(((x) => { return x.id === AffairMarketType.RENOVATION; }));
    return found !== undefined;
  }

  initMenuLinks(): void {
    this.menuLinks = [
      {
        label: 'Accueil',
        routerLink: '/dashboard',
        iconType: 'fa',
        isShow: true,
        iconName: 'th-large',
      },
      {
        label: 'Admin',
        iconType: 'fa',
        iconName: 'lock',
        toggle: 'close',
        isShow: this.isAdministrator(),
        submenu: [
          {
            label: 'Gestion des utilisateurs',
            routerLink: '/admin/users/index',
            iconType: 'letter',
            iconName: 'gu',
          },
          {
            label: 'Gestion des rôles',
            routerLink: '/admin/roles/index',
            iconType: 'letter',
            iconName: 'gr',
          },
          {
            label: 'Gestion des permissions',
            routerLink: '/admin/permissions/index',
            iconType: 'letter',
            iconName: 'gp',
          },
        ],
      },
      {
        label: 'Calendrier',
        externalLink: '/calendar',
        target: '_blank',
        iconType: 'fas',
        iconName: 'fas fa-calendar',
        isShow: this.haveRenovationMarket(),
      },
      {
        label: 'Relation client',
        iconType: 'fas',
        iconName: 'fa fa-envelope',
        toggle: 'close',
        isShow: true,
        submenu: [
          {
            label: 'Mails envoyés',
            routerLink: '/customer-relationship/mails-send',
            iconType: 'letter',
            iconName: 'ms',
          },
          {
            label: 'Réponses programmés',
            routerLink: '/customer-relationship/programmed-standards-responses',
            iconType: 'letter',
            iconName: 'ps',
          },
          {
            label: 'Sms envoyés',
            routerLink: '/customer-relationship/sms-send',
            iconType: 'letter',
            iconName: 'sm',
          },
        ],
      },
      {
        label: 'Programmation',
        iconType: 'fas',
        iconName: 'fa fa-calendar-day',
        toggle: 'close',
        isShow: this.haveRenovationMarket(),
        submenu: [
          {
            label: 'Liste des évènements',
            routerLink: '/events/index',
            iconType: 'letter',
            iconName: 'ev',
          },
          {
            label: 'Préparation',
            routerLink: '/interventions/preparation',
            iconType: 'letter',
            iconName: 'pr',
          },
        ],
      },
      {
        label: 'Affaires',
        iconType: 'fa',
        iconName: 'suitcase',
        toggle: 'close',
        isShow: true,
        submenu: [
          {
            label: 'Créer une affaire',
            routerLink: '/affairs/create',
            iconType: 'letter',
            iconName: 'af',
          },
          {
            label: 'Liste',
            routerLink: '/affairs/index',
            iconType: 'letter',
            iconName: 'lt',
          },
          {
            label: 'Liste des interventions',
            routerLink: '/interventions/index',
            iconType: 'letter',
            iconName: 'in',
            isHidden: !this.haveRenovationMarket(),
          },
          {
            label: 'Affaire groupe',
            routerLink: '/affairs/mylink/index',
            iconType: 'letter',
            iconName: 'ag',
          },
          {
            label: 'Exports',
            routerLink: '/affairs/exports',
            iconType: 'letter',
            iconName: 'ex',
          },
        ],
      },
      {
        label: 'Base client',
        iconType: 'fa',
        iconName: 'user-alt',
        toggle: 'close',
        isShow: true,
        submenu: [
          {
            label: 'Créer',
            routerLink: '/customers/create',
            iconType: 'letter',
            iconName: 'cr',
          },
          {
            label: 'Rechercher',
            routerLink: '/customers/search',
            iconType: 'letter',
            iconName: 'rc',
          },
        ],
      },
      {
        label: 'Facturation',
        iconType: 'fa',
        iconName: 'credit-card',
        toggle: 'close',
        isShow: true,
        submenu: [
          {
            label: 'Factures',
            routerLink: '/invoices/index',
            iconType: 'letter',
            iconName: 'fc',
          },
          {
            label: 'Produits à facturer',
            routerLink: '/affair-products/to-invoicing',
            iconType: 'letter',
            iconName: 'af',
          },
          {
            label: 'Devis',
            routerLink: '/quotations/index',
            iconType: 'letter',
            iconName: 'dv',
          },
          {
            label: 'Bons de commandes',
            routerLink: '/purchase-orders/index',
            iconType: 'letter',
            iconName: 'lb',
          },
        ],
      },
      {
        label: 'Comptabilité',
        iconType: 'fa',
        iconName: 'university',
        toggle: 'close',
        isShow: true,
        submenu: [
          {
            label: 'Paiements',
            routerLink: '/payments/index',
            iconType: 'letter',
            iconName: 'pm',
          },
          {
            label: 'Exports',
            routerLink: '/accounting/exports/index',
            iconType: 'letter',
            iconName: 'ex',
          },
          {
            label: 'Transactions',
            routerLink: '/etransactions/index',
            iconType: 'letter',
            iconName: 'tr',
          },
        ],
      },
      {
        label: 'Connexions',
        iconType: 'fas',
        iconName: 'fas fa-link',
        toggle: 'close',
        isShow: true,
        submenu: [
          {
            label: 'Affaires Ynergie',
            routerLink: '/affairs/ynergie/list',
            iconType: 'letter',
            iconName: 'ay',
          },
        ],
      },
      {
        label: 'Statistiques',
        iconType: 'fa',
        iconName: 'chart-line',
        toggle: 'close',
        isShow: this.isAdministrator() || this.isManager(),
        submenu: [
          {
            label: 'Volumes',
            routerLink: '/stats/volumes',
            iconType: 'letter',
            iconName: 'af',
          },
          {
            label: 'Chiffre d\'affaires',
            routerLink: '/stats/revenues',
            iconType: 'letter',
            iconName: 'ca',
          },
          {
            label: 'Détail CA',
            routerLink: '/stats/list',
            iconType: 'letter',
            iconName: 'dc',
          },
        ],
      },
      {
        label: 'Paramètres',
        iconType: 'fa',
        iconName: 'cog',
        toggle: 'close',
        isShow: this.isAdministrator(),
        submenu: [
          {
            label: 'Affaire',
            routerLink: '/settings/affairs',
            iconType: 'letter',
            iconName: 'af',
          },
          {
            label: 'Production',
            routerLink: '/settings/products',
            iconType: 'letter',
            iconName: 'pt',
          },
          {
            label: 'Facturation',
            routerLink: '/settings/invoicing',
            iconType: 'letter',
            iconName: 'ft',
          },
          {
            label: 'Interventions',
            routerLink: '/settings/interventions',
            iconType: 'letter',
            iconName: 'cp',
          },
          {
            label: 'Relation client',
            routerLink: '/settings/customer-relationship',
            iconType: 'letter',
            iconName: 'rc',
          },
          {
            label: 'Connexions',
            routerLink: '/settings/connections',
            iconType: 'letter',
            iconName: 'co',
          },
        ],
      },
    ];

  }

  private initSubscriptions() {
    this.marketsTypes$ = this.marketTypeService.getAll()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(
        (data) => {
          this.marketsTypes = data;
        },
      );

  }

}
