/* eslint-disable camelcase */
import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, ValidationErrors } from '@angular/forms';
import { Subscription, throwError } from 'rxjs';
import { AffairService, SelectService, AffairProductService, FormFormatterService, SubmitButtonService, NotifService, ContactService, AgencyService, SwalService, UnsubscribeOnDestroy } from '@eros-front/services';
import { AffairMarketType } from '@eros-front/enum';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Affair, AffairContact, AffairGoal, AffairProduct, AffairStatus, Product, ProductOffer, SelectModel, SendSetting, SendSettingType, SubmitButton } from '@eros-front/models';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AffairProductModalAddComponent } from '@libs/affairs/src/lib/affair-product-modal/affair-product-modal-add/affair-product-modal-add.component';
import { AffairOfficeFileService } from '@libs/services/src/lib/affair-office-file.service';
import { AffairShowOfficeFileModalComponent } from '@libs/affairs/src/lib/affair-show/affair-show-office-file/affair-show-office-file-modal/affair-show-office-file-modal.component';
import { AffairProductModalEditComponent } from '@libs/affairs/src/lib/affair-product-modal/affair-product-modal-edit/affair-product-modal-edit.component';
import { AffairStatusService } from '@libs/services/src/lib/affair-status.service';
import { AffairGoalService } from '@libs/services/src/lib/affair-goal.service';
import { AffairModalStandardResponseComponent } from '@libs/components/src/lib/affair-modal-standard-response/affair-modal-standard-response.component';
import Swal from 'sweetalert2';
import { MarketType } from '@libs/models/src/lib/market-type.model';

@Component({
  selector: 'app-affair-show-informations',
  templateUrl: './affair-show-informations.component.html',
  styleUrls: ['./affair-show-informations.component.scss'],
})

export class AffairShowInformationsComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges, OnDestroy {

  @Input() affair: Affair;
  @Input() agenciesSelect: SelectModel[] = [];
  @Input() mlAgenciesSelect: SelectModel[] = [];
  @Input() affairContacts: AffairContact[] = [];
  @Input() products: Product[] = [];
  @Input() assistants: SelectModel[] = [];
  @Input() techniciansItems: SelectModel[] = [];
  @Input() productsStatuses: SelectModel[] = [];
  @Input() productsUrgenciesLevels: SelectModel[] = [];
  @Input() marketsTypes: MarketType[] = [];
  @Input() affairsStatuses: AffairStatus[] = [];
  @Input() affairsGoals: AffairGoal[] = [];
  @Input() worksCompanies: SelectModel[] = [];
  @Input() sendSettings: SendSetting[] = [];
  @Input() sendSettingsTypes: SendSettingType[];
  @Input() performances: SelectModel[];
  @Input() brandsPurchasesOrders: SelectModel[];
  @Input() productsOffers: ProductOffer[] = [];
  public informationsForm: FormGroup;
  public affairOfficeFileForm: FormGroup;
  public selectedProducts = [];
  public technicianItems: any[];
  public editedAffairProduct: AffairProduct = null;
  public affairMarketTypeEnum = AffairMarketType;
  public submitButton: SubmitButton;
  public selectContacts: SelectModel[];
  public affairsGoalsSelect: SelectModel[];
  public affairsStatusesSelect: SelectModel[];

  constructor(
    private affairService: AffairService,
    private affairProductService: AffairProductService,
    private selectService: SelectService,
    private submitButtonService: SubmitButtonService,
    private notifService: NotifService,
    private modalService: NzModalService,
    private affairOfficeFileService: AffairOfficeFileService,
    private contactService: ContactService,
    private affairStatusService: AffairStatusService,
    private affairGoalService: AffairGoalService,
    private agencyService: AgencyService,
    private swalService: SwalService,
  ) {
    super();
    this.determinateSubmitButton();
  }

  ngOnInit(): void {
    if (this.affair) {
      this.selectContacts = this.contactService.formatToSelectArray(this.affair.agency.contacts);
      if (this.affairsGoals) {
        this.affairsGoalsSelect = this.affairGoalService.getSelectByMarketType(this.affairsGoals, this.affair.marketTypeId);
      }
      if (this.affairsStatuses) {
        this.affairsStatusesSelect = this.affairStatusService.getSelectByMarketType(this.affairsStatuses, this.affair.marketTypeId);
      }
      this.informationsForm = this.affairService.getInformationsForm(this.affair);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.affair && changes.affair.previousValue !== changes.affair.currentValue) {
      this.informationsForm = this.affairService.getInformationsForm(this.affair);
    }
  }

  onSubmit(): void {
    if (this.informationsForm.valid) {
      this.affairService.update(this.affair.id, this.informationsForm)
        .pipe(
          catchError((error) => {
            this.notifService.showErrorNotif(error);
            return throwError(error);
          }),
          finalize(() => {
            this.submitButtonService.setEnabled(this.submitButton);
          }),
          takeUntil(this.isDestroyed$),
        )
        .subscribe(
          (success) => {
            this.notifService.showSuccessNotif(success);
            this.affairService.get(this.affair.id);
          },
        );
    }
  }

  onModalAffairProductShow(): void {
    const modalInstance = this.modalService.create({
      nzWidth: '60%',
      nzContent: AffairProductModalAddComponent,
      nzComponentParams: {
        affair: this.affair,
        products: this.products,
        techniciansItems: this.techniciansItems,
        productsStatuses: this.productsStatuses,
      },
      nzStyle: { top: '2vh' },
    });
    modalInstance.afterClose.subscribe(() => {
      this.affairService.get(this.affair.id);
    });
  }

  onShowModalEditModalOfficeFile(): void {
    this.affairOfficeFileForm = this.affairOfficeFileService.getForm(this.affair.affairOfficeFile);
    const modalInstance = this.modalService.create({
      nzContent: AffairShowOfficeFileModalComponent,
      nzComponentParams: {
        affairOfficeFile: this.affair.affairOfficeFile,
        sendSettings: this.sendSettings,
        sendSettingsTypes: this.sendSettingsTypes,
        contacts: this.selectContacts,
        form: this.affairOfficeFileForm,
      },
      nzStyle: { top: 0 },
      nzWidth: '50%',
    });
    modalInstance.afterClose.subscribe(() => {
      this.affairOfficeFileForm = null;
      this.affairService.get(this.affair.id);
    });
  }

  onModalAffairProductEditShow(): void {
    const modalInstance = this.modalService.create({
      nzWidth: '60%',
      nzContent: AffairProductModalEditComponent,
      nzComponentParams: {
        affair: this.affair,
        products: this.products,
        techniciansItems: this.techniciansItems,
        productsStatuses: this.productsStatuses,
        productsUrgenciesLevels: this.productsUrgenciesLevels,
        selectedAffairProducts: this.affair.affairProducts,
      },
      nzStyle: { top: '2vh' },
    });
    modalInstance.afterClose
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(() => {
        this.affairService.get(this.affair.id);
        this.affairProductService.getByAffairToInvoicing(this.affair.id);
      });
  }

  onAddOfficeFile(): void {
    this.affairOfficeFileService.store(this.affair.id)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(
        (data) => {
          this.notifService.showSuccessNotif(data);
          this.affairService.get(this.affair.id);
        },
      );
  }

  onModalStandardResponseShow(): void {
    const modalInstance = this.modalService.create({
      nzTitle: 'Envoi d\'une réponse standardisée',
      nzWidth: '80%',
      nzContent: AffairModalStandardResponseComponent,
      nzComponentParams: {
        affair: this.affair,
        affairSendSettings: this.affair.affairOfficeFile.affairsSendSettings,
        affairContacts: this.affairContacts,
      },
      nzStyle: { top: '2vh' },
    });
    modalInstance.afterClose
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(() => {
        this.affairService.get(this.affair.id);
      });
  }

  validate(): void {
    const that = this;
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: 'L\'affaire sera validée',
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        that.affairService.validate(that.affair.id)
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe((success) => {
            that.notifService.showSuccessNotif(success);
            that.affairService.get(that.affair.id);
          });
      }
    });
  }

  archive(): void {
    const that = this;
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: 'L\'affaire sera archivée',
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        that.affairService.archive(that.affair.id)
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe((success) => {
            that.notifService.showSuccessNotif(success);
            that.affairService.get(that.affair.id);
          });
      }
    });
  }

  unarchive(): void {
    const that = this;
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: 'L\'affaire sera restaurée',
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        that.affairService.unarchive(that.affair.id)
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe((success) => {
            that.notifService.showSuccessNotif(success);
            that.affairService.get(that.affair.id);
          });
      }
    });
  }

  getFormControls(form: FormGroup, controlName: string): AbstractControl[] {
    return (form.get(controlName) as FormArray).controls;
  }

  getMarketTypeValue(): number {
    if (this.informationsForm) {
      return this.informationsForm.value.marketTypeId;
    }
    return null;
  }

  onChangeAgency(selected: SelectModel): void {
    this.agencyService.get(selected.value).subscribe(
      (agency) => {
        if (this.informationsForm && agency) {
          if (agency.mlAgencyId) {
            this.informationsForm.patchValue({ mlAgencyId: agency.mlAgencyId });
          }
          if (agency.userTechnicianId) {
            this.informationsForm.patchValue({ userTechnicianId: agency.userTechnicianId });
          }
        }
      },
    );

  }

  isIndividualHouseMarket(): boolean {
    return this.getMarketTypeValue() === this.affairMarketTypeEnum.STANDALONE_HOUSE;
  }

  isCollectiveMarket(): boolean {
    return this.getMarketTypeValue() === this.affairMarketTypeEnum.COLLECTIVE || this.getMarketTypeValue() === this.affairMarketTypeEnum.TERTIARY;
  }

  isRenovationMarket(): boolean {
    return this.getMarketTypeValue() === this.affairMarketTypeEnum.RENOVATION;
  }

  isOtherMarket(): boolean {
    return this.getMarketTypeValue() === this.affairMarketTypeEnum.INSPECTION;
  }

  private determinateSubmitButton(): void {
    let text = '';
    let icon = null;
    icon = faSave;
    text = 'Valider les changements';
    this.submitButton = {
      text: text,
      originalText: text,
      icon: icon,
      originalIcon: icon,
      enabled: true,
    };
    this.affairService.setSubmitButton(this.submitButton);
  }

  isValidForm(): boolean {
    return this.informationsForm.valid;
  }

  /* getFormValidationErrors() {
    Object.keys(this.informationsForm.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.informationsForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  } */

}
