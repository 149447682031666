import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RootLayout } from '@pages/layouts/root/root.component';

@Component({
  selector: 'eros-front-root',
  template: `
  <router-outlet></router-outlet>
  `,
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class AppComponent extends RootLayout implements OnInit {

  title = 'eros-bb';

  ngOnInit(): void {

  }

}
