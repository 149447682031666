import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// Eros modules
import { CoreModule } from '@eros-front/core';
import { SharedModule } from '@libs/shared/src';
import { AuthGuardService, AuthModule } from '@eros-front/auth';
import { ApiModule } from '@eros-front/api';
// Other modules
import { ChilioAngularRendererModule } from '@chilio/angular-renderer';
import { NgtllHouseModule } from '@ngtll/house';
import { NgtllFloorModule } from '@ngtll/floor';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { APPKEY } from '@libs/core/src/lib/core.shared';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { LoginLayoutComponent } from './_layouts/login-layout/login-layout.component';
// Components
import { DashboardComponent } from './dashboard/dashboard.component';
import { AppWithSidebarComponent } from './_layouts/app-with-sidebar/app-with-sidebar.component';
import { AffairShowMessagesComponent } from './affairs/affair-show/affair-show-messages/affair-show-messages.component';
import { AffairShowShuttleSheetsComponent } from './affairs/affair-show/affair-show-shuttle-sheets/affair-show-shuttle-sheets.component';
import { AffairShowInformationsComponent } from './affairs/affair-show/affair-show-informations/affair-show-informations.component';
import { AffairShowComponent } from './affairs/affair-show/affair-show.component';
import { StatisticsVolumesComponent } from './statistics/statistics-volumes/statistics-volumes.component';
import { StatisticFiltersComponent } from './statistics/statistic-filters/statistic-filters.component';
import { StatisticSettingsComponent } from './statistics/statistic-settings/statistic-settings.component';
import { StatisticsVolumesRegionsComponent } from './statistics/statistics-volumes/statistics-volumes-regions/statistics-volumes-regions.component';
import { StatisticsVolumesProductsComponent } from './statistics/statistics-volumes/statistics-volumes-products/statistics-volumes-products.component';
import { StatisticsVolumesTechniciansComponent } from './statistics/statistics-volumes/statistics-volumes-technicians/statistics-volumes-technicians.component';
import { StatisticsRevenuesComponent } from './statistics/statistics-revenues/statistics-revenues.component';
import { StatisticsRevenuesProductsComponent } from './statistics/statistics-revenues/statistics-revenues-products/statistics-revenues-products.component';
import { StatisticsRevenuesRegionsComponent } from './statistics/statistics-revenues/statistics-revenues-regions/statistics-revenues-regions.component';
import { StatisticsRevenuesTechniciansComponent } from './statistics/statistics-revenues/statistics-revenues-technicians/statistics-revenues-technicians.component';
import { StatisticsVolumesMapComponent } from './statistics/statistics-volumes/statistics-volumes-map/statistics-volumes-map.component';
import { StatisticsRevenuesMapComponent } from './statistics/statistics-revenues/statistics-revenues-map/statistics-revenues-map.component';
import { DashboardSettingsComponent } from './dashboard/dashboard-settings/dashboard-settings.component';
import { AffairShowInformationsIndividualHouseComponent } from './affairs/affair-show/affair-show-informations/affair-show-informations-individual-house/affair-show-informations-individual-house.component';
import { AffairShowInformationsCollectiveComponent } from './affairs/affair-show/affair-show-informations/affair-show-informations-collective/affair-show-informations-collective.component';
import { AffairShowMeetingsComponent } from './affairs/affair-show/affair-show-meetings/affair-show-meetings.component';
import { AffairShowMeetingsModalComponent } from './affairs/affair-show/affair-show-meetings/affair-show-meetings-modal/affair-show-meetings-modal.component';
import { AffairAbmCreateComponent } from './affairs/affair-abm-create/affair-abm-create.component';
import { AffairAbmIndividualHouseCreateComponent } from './affairs/affair-abm-create/affair-abm-individual-house-create/affair-abm-individual-house-create.component';
import { AffairAbmCollectiveCreateComponent } from './affairs/affair-abm-create/affair-abm-collective-create/affair-abm-collective-create.component';
import { AffairAbmRenovationCreateComponent } from './affairs/affair-abm-create/affair-abm-renovation-create/affair-abm-renovation-create.component';
import { AffairShowInformationsRenovationComponent } from './affairs/affair-show/affair-show-informations/affair-show-informations-renovation/affair-show-informations-renovation.component';
import { AffairShowInformationsNrjComponent } from './affairs/affair-show/affair-show-informations/affair-show-informations-nrj/affair-show-informations-nrj.component';
import { AffairShuttleSheetShowComponent } from './affairs/affair-show/affair-show-shuttle-sheets/affair-shuttle-sheet-show/affair-shuttle-sheet-show.component';
import { AffairShuttleSheetItemComponent } from './affairs/affair-show/affair-show-shuttle-sheets/affair-shuttle-sheet-show/affair-shuttle-sheet-item/affair-shuttle-sheet-item.component';
import { AffairShuttleSheetBuildingItemComponent } from './affairs/affair-show/affair-show-shuttle-sheets/affair-shuttle-sheet-show/affair-shuttle-sheet-building-item/affair-shuttle-sheet-building-item.component';
import { AffairShuttleSheetCheckboxIconComponent } from './affairs/affair-show/affair-show-shuttle-sheets/affair-shuttle-sheet-show/affair-shuttle-sheet-checkbox-icon/affair-shuttle-sheet-checkbox-icon.component';
import { AffairShuttleSheetHeatingGeneratorComponent } from './affairs/affair-show/affair-show-shuttle-sheets/affair-shuttle-sheet-show/affair-shuttle-sheet-heating-generator/affair-shuttle-sheet-heating-generator.component';
import { AffairShuttleSheetHeatingGeneratorItemComponent } from './affairs/affair-show/affair-show-shuttle-sheets/affair-shuttle-sheet-show/affair-shuttle-sheet-heating-generator-item/affair-shuttle-sheet-heating-generator-item.component';
import { AffairShuttleSheetHotWaterProductionComponent } from './affairs/affair-show/affair-show-shuttle-sheets/affair-shuttle-sheet-show/affair-shuttle-sheet-hot-water-production/affair-shuttle-sheet-hot-water-production.component';
import { AffairShuttleSheetPhotovoltaicComponent } from './affairs/affair-show/affair-show-shuttle-sheets/affair-shuttle-sheet-show/affair-shuttle-sheet-photovoltaic/affair-shuttle-sheet-photovoltaic.component';
import { AffairShuttleSheetCeilingFanComponent } from './affairs/affair-show/affair-show-shuttle-sheets/affair-shuttle-sheet-ceiling-fan/affair-shuttle-sheet-ceiling-fan.component';
import { AffairShuttleSheetTypologyComponent } from './affairs/affair-show/affair-show-shuttle-sheets/affair-shuttle-sheet-show/affair-shuttle-sheet-typology/affair-shuttle-sheet-typology.component';
import { StatisticsListComponent } from './statistics/statistics-list/statistics-list.component';
import { DashboardAffairsComponent } from './dashboard/dashboard-affairs/dashboard-affairs.component';
import { DashboardRenovationTechnicianComponent } from './dashboard/dashboard-renovation-technician/dashboard-renovation-technician.component';
import { AuditTodoListComponent } from './dashboard/dashboard-renovation-technician/audit-todo-list/audit-todo-list.component';
import { ModificationTodoListComponent } from './dashboard/dashboard-renovation-technician/modification-todo-list/modification-todo-list.component';
import { WaitingCustomerInformationsListComponent } from './dashboard/dashboard-renovation-technician/waiting-customer-informations-list/waiting-customer-informations-list.component';
import { WaitingNrjInformationsListComponent } from './dashboard/dashboard-renovation-technician/waiting-nrj-informations-list/waiting-nrj-informations-list.component';
import { AuditTodoListByTechnicianComponent } from './dashboard/dashboard-renovation-technician/audit-todo-list-by-technician/audit-todo-list-by-technician.component';
import { ModificationTodoListByTechnicianComponent } from './dashboard/dashboard-renovation-technician/modification-todo-list-by-technician/modification-todo-list-by-technician.component';
import { AuditFinalizedStatsComponent } from './dashboard/dashboard-renovation-technician/audit-finalized-stats/audit-finalized-stats.component';
import { DashboardRenovationManagerComponent } from './dashboard/dashboard-renovation-manager/dashboard-renovation-manager.component';
import { DashboardRenovationAssistantComponent } from './dashboard/dashboard-renovation-assistant/dashboard-renovation-assistant.component';
import { InvoicingTodoListComponent } from './dashboard/dashboard-renovation-assistant/invoicing-todo-list/invoicing-todo-list.component';
import { SendTodoListComponent } from './dashboard/dashboard-renovation-assistant/send-todo-list/send-todo-list.component';
import { AffairProductsToProgramListComponent } from './dashboard/dashboard-renovation-assistant/affair-products-to-program-list/affair-products-to-program-list.component';
import { AffairProductsAbmFeedbackListComponent } from './dashboard/dashboard-renovation-assistant/affair-products-abm-feedback-list/affair-products-abm-feedback-list.component';
import { AffairsMissingRitListComponent } from './dashboard/dashboard-renovation-assistant/affairs-missing-rit-list/affairs-missing-rit-list.component';
import { AffairsMissingAuditListComponent } from './dashboard/dashboard-renovation-assistant/affairs-missing-audit-list/affairs-missing-audit-list.component';
import { AffairsMissingPlansListComponent } from './dashboard/dashboard-renovation-assistant/affairs-missing-plans-list/affairs-missing-plans-list.component';
import { AffairShowIdentitySheetComponent } from './affairs/affair-show/affair-show-identity-sheet/affair-show-identity-sheet.component';

import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    AppWithSidebarComponent,
    DashboardComponent,
    AffairShowComponent,
    AffairShowInformationsComponent,
    AffairShowShuttleSheetsComponent,
    AffairShowMessagesComponent,
    StatisticsVolumesComponent,
    StatisticFiltersComponent,
    StatisticSettingsComponent,
    StatisticsVolumesRegionsComponent,
    StatisticsVolumesProductsComponent,
    StatisticsVolumesTechniciansComponent,
    StatisticsVolumesMapComponent,
    StatisticsRevenuesComponent,
    StatisticsRevenuesProductsComponent,
    StatisticsRevenuesRegionsComponent,
    StatisticsRevenuesTechniciansComponent,
    StatisticsRevenuesMapComponent,
    StatisticsListComponent,
    DashboardSettingsComponent,
    AffairShowInformationsIndividualHouseComponent,
    AffairShowInformationsCollectiveComponent,
    AffairShowMeetingsComponent,
    AffairShowMeetingsModalComponent,
    LoginLayoutComponent,
    AffairAbmCreateComponent,
    AffairAbmIndividualHouseCreateComponent,
    AffairAbmCollectiveCreateComponent,
    AffairAbmRenovationCreateComponent,
    AffairShowInformationsRenovationComponent,
    AffairShowInformationsNrjComponent,
    AffairShuttleSheetShowComponent,
    AffairShuttleSheetItemComponent,
    AffairShuttleSheetBuildingItemComponent,
    AffairShuttleSheetCheckboxIconComponent,
    AffairShuttleSheetHeatingGeneratorComponent,
    AffairShuttleSheetHeatingGeneratorItemComponent,
    AffairShuttleSheetHotWaterProductionComponent,
    AffairShuttleSheetPhotovoltaicComponent,
    AffairShuttleSheetCeilingFanComponent,
    AffairShuttleSheetTypologyComponent,
    DashboardAffairsComponent,
    // Dashboard
    DashboardRenovationTechnicianComponent,
    DashboardRenovationManagerComponent,
    DashboardRenovationAssistantComponent,
    AuditTodoListComponent,
    AuditTodoListByTechnicianComponent,
    ModificationTodoListComponent,
    ModificationTodoListByTechnicianComponent,
    WaitingCustomerInformationsListComponent,
    WaitingNrjInformationsListComponent,
    AuditFinalizedStatsComponent,
    InvoicingTodoListComponent,
    SendTodoListComponent,
    AffairProductsToProgramListComponent,
    AffairProductsAbmFeedbackListComponent,
    AffairsMissingRitListComponent,
    AffairsMissingAuditListComponent,
    AffairsMissingPlansListComponent,
    AffairShowIdentitySheetComponent,
  ],
  imports: [
    SharedModule,
    CoreModule,
    ChilioAngularRendererModule,
    NgtllHouseModule,
    NgtllFloorModule,
    NgxExtendedPdfViewerModule,
    RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' }),
    AuthModule.forRoot(environment.apiUrl),
    ApiModule.forRoot(environment.apiUrl),
  ],
  providers: [
    { provide: APPKEY, useValue: environment.appKey },
    AuthGuardService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
