import { UsersIndexComponent } from '@eros-front/users';
import { SettingsConnectionsComponent, SettingsInterventionsComponent, SettingsProductsComponent } from '@eros-front/settings';
import { Routes } from '@angular/router';
import { AuthGuardService } from '@eros-front/auth';
import { CustomersSearchComponent } from '@eros-front/customers';
// Libs
import { InvoicesTabsComponent } from '@libs/invoices/src/lib/invoices-tabs/invoices-tabs.component';
import { InvoiceManualCreateComponent } from '@libs/invoices/src/lib/invoice-manual-create/invoice-manual-create.component';
import { InvoiceShowComponent } from '@libs/invoices/src/lib/invoice-show/invoice-show.component';
import { AffairsTabsComponent } from '@libs/affairs/src/lib/affairs-tabs/affairs-tabs.component';
import { AffairsMylinkIndexComponent } from '@libs/affairs/src/lib/affairs-mylink-index/affairs-mylink-index.component';
import { AffairDocumentCustomerImportComponent } from '@libs/affairs/src/lib/affair-document-customer-import/affair-document-customer-import.component';
import { AffairDocumentDeliveredImportComponent } from '@libs/affairs/src/lib/affair-document-delivered-import/affair-document-delivered-import.component';
import { CustomersShowComponent } from '@libs/customers/src/lib/customers-show/customers-show.component';
import { LoginComponent } from '@libs/auth/src/lib/login/login.component';
import { CustomersCreateComponent } from '@libs/customers/src/lib/customers-create/customers-create.component';
import { SettingsCustomerRelationshipComponent } from '@libs/settings/src/lib/settings-customer-relationship/settings-customer-relationship.component';
import { RedirectComponent } from '@libs/auth/src/lib/redirect/redirect.component';
import { PaymentCreateComponent } from '@libs/payments/src/lib/payment-create/payment-create.component';
import { PaymentsIndexComponent } from '@libs/payments/src/lib/payments-index/payments-index.component';
import { AccountingExportsIndexComponent } from '@libs/invoices/src/lib/accounting-exports-index/accounting-exports-index.component';
import { DocumentViewerComponent } from '@libs/components/src/lib/document-viewer/document-viewer.component';
import { AppWithoutSidebarComponent } from '@libs/layouts/src';
import { AffairsExportsComponent } from '@libs/affairs/src/lib/affairs-exports/affairs-exports.component';
import { AffairsYnergieListComponent } from '@libs/affairs/src/lib/affairs-ynergie/affairs-ynergie-list/affairs-ynergie-list.component';
import { SettingsAffairsComponent } from '@libs/settings/src/lib/settings-affairs/settings-affairs.component';
import { AffairProductsToInvoicingTabsComponent } from '@libs/affairs/src/lib/affair-products/affair-products-to-invoicing/affair-products-to-invoicing-tabs/affair-products-to-invoicing-tabs.component';
import { QuotationsIndexComponent } from '@libs/quotations/src/lib/quotations-index/quotations-index.component';
import { QuotationManualCreateComponent } from '@libs/quotations/src/lib/quotation-manual-create/quotation-manual-create.component';
import { QuotationShowComponent } from '@libs/quotations/src/lib/quotation-show/quotation-show.component';
import { EtransactionsIndexComponent } from '@libs/purchase-orders/src/lib/etransactions-index/etransactions-index.component';
import { PurchasesOrdersIndexComponent } from '@libs/purchase-orders/src/lib/purchases-orders-index/purchases-orders-index.component';
import { InterventionCreateComponent } from '@libs/interventions/src/_components/interventions/intervention-create/intervention-create.component';
import { InterventionEditComponent } from '@libs/interventions/src/_components/interventions/intervention-edit/intervention-edit.component';
import { CalendarComponent } from '@libs/interventions/src/_components/calendar/calendar.component';
import { InterventionsTabsComponent } from '@libs/interventions/src/_components/interventions/interventions-tabs/interventions-tabs.component';
import { InterventionsPreparationComponent } from '@libs/interventions/src/_components/interventions/interventions-preparation/interventions-preparation.component';
import { EventsIndexComponent } from '@libs/interventions/src/_components/events/events-index/events-index.component';
import { ProgrammedStandardsResponsesListComponent } from '@libs/customer-relationship/src/lib/programmed-standards-responses/programmed-standards-responses-list/programmed-standards-responses-list.component';
import { MailsSendListComponent } from '@libs/customer-relationship/src/lib/mails-send/mails-send-list/mails-send-list.component';
import { SmsSendListComponent } from '@libs/customer-relationship/src/_components/sms/sms-send-list/sms-send-list.component';
import { AdminGuardService } from '@libs/auth/src/lib/admin-guard.service';
import { environment } from '../environments/environment';
// Abm components
import { LoginLayoutComponent } from './_layouts/login-layout/login-layout.component';
import { AppWithSidebarComponent } from './_layouts/app-with-sidebar/app-with-sidebar.component';
import { SettingsInvoicingComponent } from '../../../../libs/settings/src/lib/settings-invoicing/settings-invoicing.component';
import { UserCreateComponent } from '../../../../libs/users/src/lib/user-create/user-create.component';
import { RolesIndexComponent } from '../../../../libs/users/src/lib/roles-index/roles-index.component';
import { UserEditComponent } from '../../../../libs/users/src/lib/user-edit/user-edit.component';
import { PermissionsIndexComponent } from '../../../../libs/users/src/lib/permissions-index/permissions-index.component';
import { PermissionCreateEditComponent } from '../../../../libs/users/src/lib/permission-create-edit/permission-create-edit.component';
import { RoleCreateEditComponent } from '../../../../libs/users/src/lib/role-create-edit/role-create-edit.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AffairAbmCreateComponent } from './affairs/affair-abm-create/affair-abm-create.component';
import { AffairShowComponent } from './affairs/affair-show/affair-show.component';
import { AffairShuttleSheetShowComponent } from './affairs/affair-show/affair-show-shuttle-sheets/affair-shuttle-sheet-show/affair-shuttle-sheet-show.component';
import { StatisticsVolumesComponent } from './statistics/statistics-volumes/statistics-volumes.component';
import { StatisticsRevenuesComponent } from './statistics/statistics-revenues/statistics-revenues.component';
import { StatisticsListComponent } from './statistics/statistics-list/statistics-list.component';

export const AppRoutes: Routes = [
  {
    path: 'login',
    component: LoginLayoutComponent,
    children: [
      {
        path: '',
        component: LoginComponent,
      },
    ],
  },
  { path: 'redirect', component: RedirectComponent },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AppWithSidebarComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'affairs/create',
        component: AffairAbmCreateComponent,
      },
      {
        path: 'affairs/index',
        component: AffairsTabsComponent,
      },
      {
        path: 'affairs/:id/show',
        component: AffairShowComponent,
      },
      {
        path: 'affairs/mylink/index',
        component: AffairsMylinkIndexComponent,
      },
      {
        path: 'affairs/exports',
        component: AffairsExportsComponent,
        data: { appKey: environment.appKey },
      },
      {
        path: 'affairs/:id/document-customer/import',
        component: AffairDocumentCustomerImportComponent,
      },
      {
        path: 'affairs/:id/document-delivered/import',
        component: AffairDocumentDeliveredImportComponent,
      },
      {
        path: 'affairs-shuttles-sheets/:id',
        component: AffairShuttleSheetShowComponent,
      },
      {
        path: 'affairs/ynergie/list',
        component: AffairsYnergieListComponent,
      },
      {
        path: 'customer-relationship/programmed-standards-responses',
        component: ProgrammedStandardsResponsesListComponent,
      },
      {
        path: 'customer-relationship/mails-send',
        component: MailsSendListComponent,
      },
      {
        path: 'customer-relationship/sms-send',
        component: SmsSendListComponent,
      },
      {
        path: 'interventions/index',
        component: InterventionsTabsComponent,
      },
      {
        path: 'interventions/preparation',
        component: InterventionsPreparationComponent,
      },
      {
        path: 'events/index',
        component: EventsIndexComponent,
      },
      {
        path: 'interventions/create/affair/:id',
        component: InterventionCreateComponent,
      },
      {
        path: 'interventions/:id/edit',
        component: InterventionEditComponent,
      },
      {
        path: 'invoices/index',
        component: InvoicesTabsComponent,
      },
      {
        path: 'affair-products/to-invoicing',
        component: AffairProductsToInvoicingTabsComponent,
      },
      {
        path: 'invoices/manual/create',
        component: InvoiceManualCreateComponent,
      },
      {
        path: 'invoices/:id/show',
        component: InvoiceShowComponent,
      },
      {
        path: 'quotations/index',
        component: QuotationsIndexComponent,
      },
      {
        path: 'quotations/manual/create',
        component: QuotationManualCreateComponent,
      },
      {
        path: 'quotations/:id/show',
        component: QuotationShowComponent,
      },
      {
        path: 'customers/create',
        component: CustomersCreateComponent,
      },
      {
        path: 'customers/:id/show',
        component: CustomersShowComponent,
        data: { appKey: environment.appKey },
      },
      {
        path: 'customers/:id/show/search/:contactSearch',
        component: CustomersShowComponent,
        data: { appKey: environment.appKey },
      },
      {
        path: 'customers/search',
        component: CustomersSearchComponent,
      },
      {
        path: 'payment/create',
        component: PaymentCreateComponent,
      },
      {
        path: 'payments/index',
        component: PaymentsIndexComponent,
      },
      {
        path: 'accounting/exports/index',
        component: AccountingExportsIndexComponent,
      },
      {
        path: 'stats/volumes',
        component: StatisticsVolumesComponent,
      },
      {
        path: 'stats/revenues',
        component: StatisticsRevenuesComponent,
      },
      {
        path: 'stats/list',
        component: StatisticsListComponent,
      },
      {
        path: 'etransactions/index',
        component: EtransactionsIndexComponent,
      },
      {
        path: 'purchase-orders/index',
        component: PurchasesOrdersIndexComponent,
      },
    ],
  },
  {
    path: '',
    component: AppWithoutSidebarComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'calendar',
        component: CalendarComponent,
      },
      {
        path: 'document/preview',
        component: DocumentViewerComponent,
      },
    ],
  },
  {
    path: '',
    component: AppWithSidebarComponent,
    canActivate: [AdminGuardService],
    children: [
      {
        path: 'admin/users/index',
        component: UsersIndexComponent,
      },
      {
        path: 'admin/user/create',
        component: UserCreateComponent,
      },
      {
        path: 'admin/users/:id',
        component: UserEditComponent,
      },
      {
        path: 'admin/roles/index',
        component: RolesIndexComponent,
      },
      {
        path: 'admin/permissions/index',
        component: PermissionsIndexComponent,
      },
      {
        path: 'admin/permission/create',
        component: PermissionCreateEditComponent,
      },
      {
        path: 'admin/permission/:id/edit',
        component: PermissionCreateEditComponent,
      },
      {
        path: 'admin/role/create',
        component: RoleCreateEditComponent,
      },
      {
        path: 'admin/role/:id/edit',
        component: RoleCreateEditComponent,
      },
      {
        path: 'settings/affairs',
        component: SettingsAffairsComponent,
        data: { appKey: environment.appKey },
      },
      {
        path: 'settings/products',
        component: SettingsProductsComponent,
      },
      {
        path: 'settings/invoicing',
        component: SettingsInvoicingComponent,
      },
      {
        path: 'settings/interventions',
        component: SettingsInterventionsComponent,
      },
      {
        path: 'settings/customer-relationship',
        component: SettingsCustomerRelationshipComponent,
      },
      {
        path: 'settings/connections',
        component: SettingsConnectionsComponent,
      },
    ],
  },

];
