import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { tabSuffix } from '_config/tab-suffix';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { AffairContactService, AffairService, ThingService, SelectService, UnsubscribeOnDestroy, PayerService, InterventionService, NotifService } from '@eros-front/services';
import { Subscription } from 'rxjs';
import { Affair, AffairContact, Payer, ProductOffer, SelectModel } from '@libs/models/src';
import { NgxSpinnerService } from 'ngx-spinner';
import { AffairMarketType } from '@libs/enum/src';
import { environment } from 'apps/eros-abm/src/environments/environment';
import { MarketType } from '@libs/models/src/lib/market-type.model';
import { takeUntil } from 'rxjs/operators';

type TAB_TYPE = 'INFORMATIONS' | 'PROGRAMMATION' | 'DOCUMENTS_CUSTOMERS' | 'DOCUMENTS_DELIVERED' | 'CONTACTS' | 'MEETINGS' | 'MESSAGES' | 'IDENTITY_SHEET'
  | 'SHUTTLES_SHEETS' | 'INVOICING' | 'QUOTATIONS' | 'MAILS' | 'YNERGIE' | 'HISTORY';

@Component({
  selector: 'app-affair-show',
  templateUrl: './affair-show.component.html',
  styleUrls: ['./affair-show.component.scss'],
})
export class AffairShowComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {

  public affair: Affair;
  private affair$: Subscription;
  public agenciesSelect: SelectModel[] = [];
  public mlAgenciesSelect: SelectModel[] = [];
  public payersByAgencySelect: Payer[] = [];
  public affairContacts: AffairContact[] = [];
  public affairSelectsForShow$: Subscription;
  public products: SelectModel[] = [];
  public technicians: SelectModel[] = [];
  public productsStatuses: SelectModel[] = [];
  public productsUrgenciesLevels: SelectModel[] = [];
  public marketsTypes: MarketType[] = [];
  public affairsStatuses: SelectModel[] = [];
  public assistants: SelectModel[] = [];
  public affairsGoals: SelectModel[] = [];
  public sendSettings: SelectModel[] = [];
  public sendSettingsTypes: SelectModel[] = [];
  public worksCompanies: SelectModel[] = [];
  public performances: SelectModel[] = [];
  public productsOffers: ProductOffer[] = [];
  public affairMarketTypeEnum = AffairMarketType;
  public appKey = environment.appKey;
  public brandsPurchasesOrders: SelectModel[];
  public activeTab: TAB_TYPE = 'INFORMATIONS';
  public affairsSelectsInit = false;
  public affairProductsToProgram: any = [];

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private affairService: AffairService,
    private affairContactService: AffairContactService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private thingService: ThingService,
    private payerService: PayerService,
    private interventionService: InterventionService,
    private notifService: NotifService,
  ) {
    super();
    // override the route reuse strategy
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.initSubscriptions();
  }

  ngOnInit(): void {
    this.initValues();
    this.getValues();
    this.thingService.getBrandsPurchasesOrders();
  }

  ngOnDestroy(): void {
    this.affair$.unsubscribe();
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (!this.affairService.checkIfAffairRoute(this.affair.id, event.urlAfterRedirects)) {
          this.affairService.reset();
        }
      }
    });
  }

  onTabChanged(event: TAB_TYPE): void {
    this.activeTab = event;
  }

  isCollectiveMarket(): boolean {
    return this.affair.marketTypeId === this.affairMarketTypeEnum.COLLECTIVE || this.affair.marketTypeId === this.affairMarketTypeEnum.TERTIARY;
  }

  isRenovationMarket(): boolean {
    return this.affair.marketTypeId === this.affairMarketTypeEnum.RENOVATION;
  }

  isStandaloneHouseMarket(): boolean {
    return this.affair.marketTypeId === this.affairMarketTypeEnum.STANDALONE_HOUSE;
  }

  private initValues() {
    this.affair = null;
    this.agenciesSelect = [];
    this.mlAgenciesSelect = [];
    this.payersByAgencySelect = [];
  }

  private getValues() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const id = Number(params.get('id'));
      if (this.affairService.checkIfDifferentId(id)) {
        this.spinner.show('load-affair');
        this.affairService.get(id);
        this.thingService.getBrandsPurchasesOrders()
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe(
            (brands) => {
              this.brandsPurchasesOrders = brands;
            },
          );
        this.affairService.getSelectsForShow(id)
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe(
            (data) => {
              if (data) {
                this.agenciesSelect = data.agencies;
                this.mlAgenciesSelect = data.mlAgencies;
                this.products = data.products;
                this.technicians = data.technicians;
                this.productsStatuses = data.productsStatuses;
                this.productsUrgenciesLevels = data.productsUrgenciesLevels;
                this.productsOffers = data.productsOffers;
                this.marketsTypes = data.marketsTypes;
                this.affairsStatuses = data.affairsStatuses;
                this.assistants = data.assistants;
                this.affairsGoals = data.affairsGoals;
                this.sendSettings = data.sendSettings;
                this.sendSettingsTypes = data.sendSettingsTypes;
                this.worksCompanies = data.worksCompanies;
                this.performances = data.performances;
                this.affairsSelectsInit = true;
              }
            },
          );
      }
    });
  }

  private initSubscriptions() {
    this.affair$ = this.affairService.affair$.subscribe(
      (affair) => {
        if (affair) {
          this.affair = affair;
          this.spinner.hide('load-affair');
          this.initWhenAffair();
        }
      },
    );
  }

  private initWhenAffair() {
    this.titleService.setTitle(`Gestion affaire : ${this.affair.mlRef}${tabSuffix}`);
    this.initPayersSelect();
    this.affairContactService.getAllByAffair(this.affair.id)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(
        (contacts: AffairContact[]) => {
          this.affairContacts = contacts;
          if (contacts.length === 0 && this.affair.isAffairContactsNeeded) {
            this.notifService.showWarningNotif("Attention, une ou plusieurs conditions d'envoie nécessitent un contact affaire.");
          }
        },
      );
    this.interventionService.getAffairsProductsToProgram(null, this.affair.id).subscribe((data) => {
      this.affairProductsToProgram = data;
    });
  }

  private initPayersSelect() {
    this.payerService.getPayersByAgency(this.affair.agencyId, this.affair.marketTypeId)
      .pipe(
        takeUntil(this.isDestroyed$),
      )
      .subscribe((data) => {
        this.payersByAgencySelect = data;
      });
  }

}
