import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { AuthentificationService } from '@libs/auth/src';
import { AffairMarketType } from '@libs/enum/src';
import { AffairGoal, SelectModel, SubmitButton } from '@libs/models/src';
import { AffairService, AgencyService, AutocompleteOptions, CustomLocalStorageService, FormFormatterService, MarketTypeService, MlAgencyService, SelectService, SubmitButtonService, UnsubscribeOnDestroy, UserService } from '@libs/services/src';
import { AffairGoalService } from '@libs/services/src/lib/affair-goal.service';
import { AffairMyLinkService } from '@libs/services/src/lib/affair-mylink.service';
import { StudyOfficeService } from '@libs/services/src/lib/study-office.service';
import { tabSuffix } from '_config/tab-suffix';
import { Subscription, zip } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { MarketType } from '@libs/models/src/lib/market-type.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-affair-abm-create',
  templateUrl: './affair-abm-create.component.html',
  styleUrls: ['./affair-abm-create.component.scss'],
})
export class AffairAbmCreateComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {

  public submitButton: SubmitButton;
  public agencies: SelectModel[] = [];
  public mlAgenciesSelect: SelectModel[] = [];
  public marketsTypes: MarketType[] = [];
  public form: FormGroup;
  public affairMarketTypeEnum = AffairMarketType;
  private affairsSearch$: Subscription;
  public autocompleteOptions: AutocompleteOptions = new AutocompleteOptions();
  public affairsGoals: AffairGoal[] = [];
  public affairsGoalsSelect: SelectModel[] = [];
  public affairsMyLinkResults = [];
  public finalCustomerLastnameChanged = '';
  public workSiteLocalityChanged = '';
  public displayResults = false;
  public selectedMarketsTypesIds = [];
  public technicians = [];

  constructor(
    private titleService: Title,
    private affairService: AffairService,
    private agencyService: AgencyService,
    private mlAgencyService: MlAgencyService,
    private marketTypeService: MarketTypeService,
    private submitButtonService: SubmitButtonService,
    private affairMyLinkService: AffairMyLinkService,
    private affairGoalService: AffairGoalService,
    private changeDetector: ChangeDetectorRef,
    private localStorage: CustomLocalStorageService,
    private selectService: SelectService,
    private authService: AuthentificationService,
    private spinnerService: NgxSpinnerService,
    private userService: UserService,
  ) {
    super();
    this.titleService.setTitle(`Création d'une affaire${tabSuffix}`);
    this.initSubscriptions();
  }

  ngOnInit(): void {
    this.getValues();
    this.setSubmitButton();
    this.form = this.affairService.getInformationsForm(null);
    this.formChanges();
  }

  searchOnMyLink(): void {
    this.affairMyLinkService.search(this.form);
  }

  ngOnDestroy(): void {
    this.affairsSearch$.unsubscribe();
    this.affairsMyLinkResults = [];
    this.form = null;
    this.displayResults = false;
  }

  ngAfterViewInit(): void {
    this.displayResults = false;
    this.changeDetector.detectChanges();
  }

  onSubmit(): void {
    if (this.isValidForm()) {
      this.affairService.create(this.form);
    }
  }

  getMarketTypeValue(): number {
    if (this.form) {
      return this.form.value.marketTypeId;
    }
    return this.affairMarketTypeEnum.STANDALONE_HOUSE;
  }

  isIndividualHouseMarket(): boolean {
    return this.getMarketTypeValue() === this.affairMarketTypeEnum.STANDALONE_HOUSE;
  }

  isCollectiveMarket(): boolean {
    return this.getMarketTypeValue() === this.affairMarketTypeEnum.COLLECTIVE || this.getMarketTypeValue() === this.affairMarketTypeEnum.TERTIARY;
  }

  isRenovationMarket(): boolean {
    return this.getMarketTypeValue() === this.affairMarketTypeEnum.RENOVATION;
  }

  isOtherMarket(): boolean {
    return this.getMarketTypeValue() === this.affairMarketTypeEnum.STANDALONE_HOUSE
      || this.getMarketTypeValue() === this.affairMarketTypeEnum.RENOVATION
      || this.getMarketTypeValue() === this.affairMarketTypeEnum.INSPECTION;
  }

  isValidForm(): boolean {
    return this.form.valid;
  }

  onChangeAgency(selected: SelectModel): void {
    if (selected != null) {
      this.agencyService.get(selected.value)
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe(
          (agency) => {
            if (this.form && agency) {
              if (agency.mlAgencyId) {
                this.form.patchValue({ mlAgencyId: agency.mlAgencyId });
              }
              if (agency.userTechnicianId) {
                this.form.patchValue({ userTechnicianId: agency.userTechnicianId });
              }
            }
          },
        );
    }
  }

  private searchValuesMyLinkChanged(form: FormGroup): boolean {
    return this.finalCustomerLastnameChanged !== '' && this.workSiteLocalityChanged !== '';
  }

  private getValues(): void {
    this.spinnerService.show('load-create-affair');
    zip(
      this.agencyService.getForSelect(),
      this.mlAgencyService.getForSelect(),
      this.marketTypeService.getAll(),
      this.affairGoalService.getAll(),
      this.userService.getTechniciansSelect(),
    ).pipe(
      takeUntil(this.isDestroyed$),
      finalize(() => {
        this.spinnerService.hide('load-create-affair');
      }),
    )
      .subscribe(
        ([agencies, mlAgencies, marketsTypes, affairsGoals, technicians]) => {
          this.agencies = agencies;
          this.mlAgenciesSelect = mlAgencies;
          this.marketsTypes = marketsTypes;
          this.affairsGoals = affairsGoals;
          this.technicians = technicians;
          this.selectedMarketsTypesIds = this.localStorage.getSelectedMarketsTypesIds();
          this.updateAffairsGoalsByMarketType(this.selectedMarketsTypesIds[0]);
        },
      );
  }

  private initSubscriptions(): void {
    this.affairsSearch$ = this.affairMyLinkService.affairsSearch$.subscribe(
      (data) => {
        if (data) {
          this.affairsMyLinkResults = data;
          this.displayResults = this.affairsMyLinkResults.length > 0;
        }
      },
    );
  }

  private setSubmitButton(): void {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'l\'affaire',
      text: 'Enregistrer',
      icon: faSave,
    });
    this.affairService.setSubmitButton(this.submitButton);
  }

  private updateAffairsGoalsByMarketType(marketTypeId: number): void {
    this.affairsGoalsSelect = [];
    this.affairsGoals.forEach((affairGoal: AffairGoal) => {
      if (affairGoal.marketsTypes.find((x) => { return x.id === marketTypeId; })) {
        this.affairsGoalsSelect.push({
          label: affairGoal.name,
          value: affairGoal.id,
        });
      }
    });
  }

  private formChanges(): void {
    this.form.controls.finalCustomerLastname.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((selectedValue) => {
        this.finalCustomerLastnameChanged = selectedValue;
        if (this.searchValuesMyLinkChanged(this.form)) {
          setTimeout(() => { return this.affairMyLinkService.search(this.form); });
        }
      });

    this.form.controls.workSiteLocality.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((selectedValue) => {
        this.workSiteLocalityChanged = selectedValue;
        if (this.searchValuesMyLinkChanged(this.form)) {
          setTimeout(() => { return this.affairMyLinkService.search(this.form); });
        }
      });

    this.form.controls.marketTypeId.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((value) => {
        if (this.affairsGoals) {
          this.updateAffairsGoalsByMarketType(value);
        }
      });
  }

}
