<form
  [formGroup]="form"
  *ngIf="form">
  <div class="row">
    <div class="col">
      <pgcard
        [Refresh]="false"
        [Toggle]="true"
        [Close]="false"
        [Maximize]="false"
        [HeaderClass]="'card-header-primary'"
        [HeaderIcon]="'fas fa-info'">
        <ng-template #CardTitle>Informations chantier</ng-template>
        <div class="form-group">
          <label class="required">Agence cliente</label>
          <ng-select
            [items]="agencies"
            [multiple]="false"
            [virtualScroll]="true"
            bindLabel="label"
            bindValue="value"
            formControlName="agencyId"
            (change)="onChangeAgency($event)">
          </ng-select>
        </div>
        <markets-types-selection
          [keyPrefix]="'affair-show-informations'"
          [marketsTypes]="marketsTypes"
          [form]="form">
        </markets-types-selection>
        <app-affair-form-work-site-address [form]="form"></app-affair-form-work-site-address>
      </pgcard>
      <pgcard
        [Refresh]="false"
        [Toggle]="true"
        [Close]="false"
        [Maximize]="false"
        [HeaderClass]="'card-header-primary'"
        [HeaderIcon]="'fas fa-briefcase'">
        <ng-template #CardTitle>Informations affaire</ng-template>
        <div class="row">
          <div class="col p-0">
            <div class="form-group">
              <label class="required">Agence responsable</label>
              <ng-select
                [items]="mlAgencies"
                [multiple]="false"
                bindLabel="label"
                bindValue="value"
                formControlName="mlAgencyId">
              </ng-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col pl-0">
            <div class="form-group">
              <label>Objectif(s)</label>
              <ng-select-multiple
                class="ng-select-sm w-100"
                [placeholder]="'Sélectionnez un ou plusieurs objectif(s)'"
                [items]="affairsGoals"
                [selectAllText]="'Tous les objectifs'"
                [selectAllFilteredText]="'Toutes les objectifs filtrés'"
                [deselectAllText]="'Aucun objectif'"
                [countSelectedText]="'objectifs sélectionnés'"
                [virtualScroll]="true"
                formControlName="affairsGoalsIds">
              </ng-select-multiple>
            </div>
          </div>
          <div class="col pr-0">
            <div class="form-group">
              <label>Technicien(ne)</label>
              <ng-select
                [items]="technicians"
                [multiple]="false"
                bindLabel="label"
                bindValue="value"
                formControlName="userTechnicianId">
              </ng-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col pl-0">
            <div class="form-group">
              <label>Date de livraison</label>
              <nz-date-picker
                class="d-block w-100"
                nzPlaceHolder
                [nzFormat]="'dd/MM/yyyy'"
                formControlName="deliveryDate">
              </nz-date-picker>
            </div>
          </div>
          <div class="col pr-0">
            <div class="form-group">
              <label>Année de construction</label>
              <nz-year-picker
                class="d-block w-100"
                nzPlaceHolder
                formControlName="constructionYear">
              </nz-year-picker>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Référence devis</label>
          <input
            type="text"
            class="form-control input-sm"
            formControlName="costEstimateRef">
        </div>
      </pgcard>
    </div>
    <div class="col">
      <pgcard
        [Refresh]="false"
        [Toggle]="true"
        [Close]="false"
        [Maximize]="false"
        [HeaderClass]="'card-header-primary'"
        [HeaderIcon]="'fas fa-info'">
        <ng-template #CardTitle>Informations maître d'ouvrage</ng-template>
        <div class="form-group">
          <div class="row text-center">
            <div class="col-6">
              <i class="fa fa-user"></i>
            </div>
            <div class="col-6">
              <i class="fa fa-user-friends"></i>
            </div>
          </div>
          <div class="row">
            <div class="col final-customer-div mr-1">
              <div class="form-group">
                <label class="required">Nom</label>
                <input
                  type="text"
                  class="form-control input-sm"
                  formControlName="finalCustomerLastname">
              </div>
              <div class="form-group">
                <label>Prénom</label>
                <input
                  type="text"
                  class="form-control input-sm"
                  formControlName="finalCustomerFirstname">
              </div>
            </div>
            <div class="col final-customer-div ml-1">
              <div class="form-group">
                <label>Nom</label>
                <input
                  type="text"
                  class="form-control input-sm"
                  formControlName="finalCustomerLastnameSecondary">
              </div>
              <div class="form-group">
                <label>Prénom</label>
                <input
                  type="text"
                  class="form-control input-sm"
                  formControlName="finalCustomerFirstnameSecondary">
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col pl-0">
              <label>E-mail</label>
              <input-email
                [form]="form"
                [inputClass]="'form-control input-sm'"
                [inputFormControlName]="'finalCustomerEmail'">
              </input-email>
            </div>
            <div class="col pr-0">
              <label>Téléphone</label>
              <input-phone
                [form]="form"
                [inputClass]="'form-control input-sm'"
                [inputFormControlName]="'finalCustomerPhone'">
              </input-phone>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col pl-0">
              <label>N° dossier</label>
              <input
                type="text"
                class="form-control input-sm"
                formControlName="folderNumber">
            </div>
            <div class="col pr-0">
              <label>Référence client</label>
              <input
                type="text"
                class="form-control input-sm"
                formControlName="customerRef">
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Commentaire facture</label>
          <input
            type="text"
            class="form-control input-sm"
            formControlName="invoiceComment">
        </div>
        <app-affair-form-final-customer-address [form]="form"></app-affair-form-final-customer-address>
      </pgcard>
    </div>
  </div>
</form>
