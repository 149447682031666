<form
  [formGroup]="informationsForm"
  autocomplete="off"
  *ngIf="affair && informationsForm">
  <div class="fixed-div-btns row justify-content-center mb-3">
    <submit-button
      [isValidForm]="isValidForm()"
      [submitButton]="submitButton"
      (onButtonClicked)="onSubmit()"
      [isXxlButton]="true">
    </submit-button>
    <button
      class="btn btn-complete btn-custom-xxl mx-1"
      tooltip="Ajouter un produit"
      (click)="onModalAffairProductShow()"
      type="button">
      <i class="fas fa-cart-plus"></i>
    </button>
    <button
      class="btn btn-warning btn-custom-xxl mx-1"
      tooltip="Editer un ou plusieurs produit"
      (click)="onModalAffairProductEditShow()"
      type="button"
      *ngIf="affair.affairProducts && affair.affairProducts.length > 0">
      <i class="fas fa-shopping-basket"></i>
    </button>
    <button
      class="btn btn-info btn-custom-xxl mx-1"
      tooltip="Envoyer une réponse standardisée"
      (click)="onModalStandardResponseShow()"
      *ngIf="affair.affairOfficeFile && affair.affairProducts && affair.affairProducts.length > 0"
      type="button">
      <i class="fas fa-envelope"></i>
    </button>
    <button
      class="btn btn-warning btn-custom-xxl mx-1"
      tooltip="Editer la fiche bureau affaire"
      (click)="onShowModalEditModalOfficeFile()"
      *ngIf="affair.affairOfficeFile"
      type="button">
      <i class="fas fa-file-alt"></i>
    </button>
    <button
      class="btn btn-success btn-custom-xxl mx-1"
      tooltip="Ajouter une fiche bureau affaire"
      (click)="onAddOfficeFile()"
      *ngIf="!affair.affairOfficeFile"
      type="button">
      <i class="fas fa-file-upload"></i>
    </button>
    <button
      class="btn btn-complete btn-custom-xxl mx-1"
      tooltip="Valider l'affaire"
      type="button"
      (click)="validate()"
      *ngIf="affair.isDraft || affair.isWaitingValidation">
      <i class="far fa-check-double"></i>
    </button>
    <button
      class="btn btn-info btn-custom-xxl mx-1"
      tooltip="Archiver l'affaire"
      type="button"
      (click)="archive()"
      *ngIf="!affair.isArchived">
      <i class="fas fa-archive"></i>
    </button>
    <button
      class="btn btn-info btn-custom-xxl mx-1"
      tooltip="Restaurer l'affaire"
      type="button"
      (click)="unarchive()"
      *ngIf="affair.isArchived">
      <i class="fas fa-trash-undo"></i>
    </button>
  </div>
  <div
    class="row"
    [affair]="affair"
    *ngIf="affair && informationsForm">
    <div
      class="col"
      *ngIf="isIndividualHouseMarket()">
      <app-affair-show-informations-individual-house
        *ngIf="affair && informationsForm"
        [form]="informationsForm"
        [agencies]="agenciesSelect"
        [assistants]="assistants"
        [marketsTypes]="marketsTypes"
        [mlAgencies]="mlAgenciesSelect"
        [technicians]="techniciansItems"
        [affairsGoals]="affairsGoalsSelect"
        [brandsPurchasesOrders]="brandsPurchasesOrders"
        [affairsStatuses]="affairsStatusesSelect"
        (changeAgency)="onChangeAgency($event)">
      </app-affair-show-informations-individual-house>
    </div>
    <div
      class="col"
      *ngIf="isRenovationMarket()">
      <app-affair-show-informations-renovation
        *ngIf="affair && informationsForm"
        [form]="informationsForm"
        [affair]="affair"
        [agencies]="agenciesSelect"
        [assistants]="assistants"
        [marketsTypes]="marketsTypes"
        [mlAgencies]="mlAgenciesSelect"
        [technicians]="techniciansItems"
        [affairsGoals]="affairsGoalsSelect"
        [worksCompanies]="worksCompanies"
        [affairsStatuses]="affairsStatusesSelect"
        (changeAgency)="onChangeAgency($event)">
      </app-affair-show-informations-renovation>
    </div>
    <div
      class="col"
      *ngIf="isOtherMarket()">
      <app-affair-form-work-site-informations
        *ngIf="affair && informationsForm"
        [affair]="affair"
        [form]="informationsForm"
        [agencies]="agenciesSelect"
        [marketsTypes]="marketsTypes">
      </app-affair-form-work-site-informations>
      <app-affair-form-affair-informations
        [affair]="affair"
        *ngIf="affair && informationsForm"
        [form]="informationsForm"
        [mlAgencies]="mlAgenciesSelect"
        [assistants]="assistants"
        [technicians]="techniciansItems"
        [affairsGoals]="affairsGoalsSelect"
        [affairsStatuses]="affairsStatusesSelect">
      </app-affair-form-affair-informations>
      <app-affair-form-final-customer-informations
        [form]="informationsForm"
        *ngIf="affair && informationsForm"
        [affair]="affair">
      </app-affair-form-final-customer-informations>
    </div>
    <div class="col">
      <app-affair-form-affair-products
        *ngIf="affair && informationsForm && techniciansItems"
        [affair]="affair"
        [products]="products"
        [techniciansItems]="techniciansItems"
        [productsStatuses]="productsStatuses"
        [agenciesSelect]="agenciesSelect"
        [mlAgenciesSelect]="mlAgenciesSelect"
        [productsOffers]="productsOffers">
      </app-affair-form-affair-products>
      <app-affair-show-verifications [affair]="affair"></app-affair-show-verifications>
      <notebook-process [affair]="affair"></notebook-process>
      <app-affair-form-notes
        *ngIf="affair && informationsForm"
        [affair]="affair"
        [form]="informationsForm">
      </app-affair-form-notes>
      <ng-container *ngIf="isRenovationMarket()">
        <app-affair-show-informations-nrj [affair]="affair"></app-affair-show-informations-nrj>
      </ng-container>
      <app-affair-show-office-file
        [affair]="affair"
        [affairOfficeFile]="affair.affairOfficeFile"
        [affairContacts]="affairContacts"
        *ngIf="affair.affairOfficeFile">
      </app-affair-show-office-file>
    </div>
    <div
      class="col"
      *ngIf="isCollectiveMarket()">
      <app-affair-show-informations-collective
        *ngIf="affair && informationsForm"
        [affair]="affair"
        [form]="informationsForm"
        [agencies]="agenciesSelect"
        [assistants]="assistants"
        [marketsTypes]="marketsTypes"
        [mlAgencies]="mlAgenciesSelect"
        [technicians]="techniciansItems"
        [affairsGoals]="affairsGoalsSelect"
        [affairsStatuses]="affairsStatusesSelect"
        [performances]="performances">
      </app-affair-show-informations-collective>
    </div>
  </div>
</form>
