<custom-spinner
  [name]="'load-affair'"
  [text]="'Chargement de l\'affaire...'"></custom-spinner>
<app-affair-show-monitoring
  [affair]="affair"
  *ngIf="affair && isRenovationMarket()"></app-affair-show-monitoring>
<div
  class="my-2 mx-3"
  *ngIf="affair">
  <div class="card card-container">
    <app-affair-show-headband
      [affair]="affair"
      [appKey]="appKey"
      [isRenovationMarket]="isRenovationMarket()"></app-affair-show-headband>
    <nz-tabset
      nzCentered
      nzType="card"
      *ngIf="affair">
      <nz-tab
        [nzTitle]="informationsTemplate"
        (nzSelect)="activeTab = 'INFORMATIONS'">
        <ng-template #informationsTemplate>
          <i class="fas fa-info-circle"></i>
          <span>Informations</span>
        </ng-template>
      </nz-tab>
      <nz-tab
        [nzTitle]="programmationTemplate"
        (nzSelect)="activeTab = 'PROGRAMMATION'"
        *ngIf="isRenovationMarket()">
        <ng-template #programmationTemplate>
          <i class="fal fa-calendar-alt"></i>
          <span>Programmation</span>
        </ng-template>
      </nz-tab>
      <nz-tab
        [nzTitle]="documentsCustomersTemplate"
        (nzSelect)="activeTab = 'DOCUMENTS_CUSTOMERS'">
        <ng-template #documentsCustomersTemplate>
          <i class="far fa-file-import"></i>
          <span>Données d'entrée</span>
        </ng-template>
      </nz-tab>
      <nz-tab
        [nzTitle]="documentsDeliveredTemplate"
        (nzSelect)="activeTab = 'DOCUMENTS_DELIVERED'">
        <ng-template #documentsDeliveredTemplate>
          <i class="far fa-file-export"></i>
          <span>Données de sortie</span>
        </ng-template>
      </nz-tab>
      <nz-tab
        [nzTitle]="contactsTemplate"
        (nzSelect)="activeTab = 'CONTACTS'">
        <ng-template #contactsTemplate>
          <i class="fal fa-address-card"></i>
          <span>Contacts</span>
        </ng-template>
      </nz-tab>
      <nz-tab
        [nzTitle]="meetingsTemplate"
        (nzSelect)="activeTab = 'MEETINGS'"
        *ngIf="isCollectiveMarket()">
        <ng-template #meetingsTemplate>
          <i class="far fa-handshake"></i>
          <span>Réunions</span>
        </ng-template>
      </nz-tab>
      <nz-tab
        [nzTitle]="messagesTemplate"
        (nzSelect)="activeTab = 'MESSAGES'">
        <ng-template #messagesTemplate>
          <i class="far fa-comments"></i>
          <span>Messages</span>
        </ng-template>
      </nz-tab>
      <nz-tab
        [nzTitle]="identitySheetTemplate"
        (nzSelect)="activeTab = 'IDENTITY_SHEET'">
        <ng-template #identitySheetTemplate>
          <i class="fas fa-passport"></i>
          <span>Fiche d'identité</span>
        </ng-template>
      </nz-tab>
      <nz-tab
        [nzTitle]="shuttlesSheetsTemplate"
        (nzSelect)="activeTab = 'SHUTTLES_SHEETS'"
        *ngIf="isStandaloneHouseMarket()">
        <ng-template #shuttlesSheetsTemplate>
          <i class="fas fa-file-spreadsheet"></i>
          <span>Fiches navettes</span>
        </ng-template>
      </nz-tab>
      <nz-tab
        [nzTitle]="invoicingTemplate"
        (nzSelect)="activeTab = 'INVOICING'">
        <ng-template #invoicingTemplate>
          <i class="far fa-money-check-edit-alt"></i>
          <span>Facturation</span>
        </ng-template>
      </nz-tab>
      <nz-tab
        [nzTitle]="quotationsTemplate"
        (nzSelect)="activeTab = 'QUOTATIONS'">
        <ng-template #quotationsTemplate>
          <i class="far fa-file-invoice"></i>
          <span>Devis</span>
        </ng-template>
      </nz-tab>
      <nz-tab
        [nzTitle]="mailsTemplate"
        (nzSelect)="activeTab = 'MAILS'">
        <ng-template #mailsTemplate>
          <i class="far fa-envelope"></i>
          <span>Mails</span>
        </ng-template>
      </nz-tab>
      <nz-tab
        [nzTitle]="affairYnergieTemplate"
        (nzSelect)="activeTab = 'YNERGIE'"
        *ngIf="affair.affairYnergieId">
        <ng-template #affairYnergieTemplate>
          <i class="fab fa-connectdevelop"></i>
          <span>Ynergie</span>
        </ng-template>
      </nz-tab>
      <nz-tab
        [nzTitle]="historyTemplate"
        (nzSelect)="activeTab = 'HISTORY'">
        <ng-template #historyTemplate>
          <i class="fas fa-history"></i>
          <span>Historique</span>
        </ng-template>
      </nz-tab>
    </nz-tabset>
  </div>
</div>
<app-affair-show-informations
  *ngIf="affair && affairsSelectsInit"
  [class.d-none]="activeTab !== 'INFORMATIONS'"
  [affair]="affair"
  [agenciesSelect]="agenciesSelect"
  [mlAgenciesSelect]="mlAgenciesSelect"
  [affairContacts]="affairContacts"
  [products]="products"
  [assistants]="assistants"
  [techniciansItems]="technicians"
  [productsStatuses]="productsStatuses"
  [productsUrgenciesLevels]="productsUrgenciesLevels"
  [marketsTypes]="marketsTypes"
  [affairsStatuses]="affairsStatuses"
  [affairsGoals]="affairsGoals"
  [worksCompanies]="worksCompanies"
  [sendSettings]="sendSettings"
  [sendSettingsTypes]="sendSettingsTypes"
  [performances]="performances"
  [brandsPurchasesOrders]="brandsPurchasesOrders"
  [productsOffers]="productsOffers">
</app-affair-show-informations>
<app-affair-show-program
  *ngIf="affair"
  [class.d-none]="activeTab !== 'PROGRAMMATION'"
  [affair]="affair"
  [affairProductsToProgram]="affairProductsToProgram"
  [applicationDomainVerifications]="applicationDomainVerifications"
  [buildingObject]="buildingObject">
</app-affair-show-program>
<app-affair-show-documents-delivered
  *ngIf="affair"
  [class.d-none]="activeTab !== 'DOCUMENTS_DELIVERED'"
  [affair]="affair"
  [appKey]="appKey">
</app-affair-show-documents-delivered>
<app-affair-show-documents-customers
  *ngIf="affair"
  [class.d-none]="activeTab !== 'DOCUMENTS_CUSTOMERS'"
  [affair]="affair">
</app-affair-show-documents-customers>
<app-affair-show-contacts
  *ngIf="affair"
  [class.d-none]="activeTab !== 'CONTACTS'"
  [affair]="affair"
  [affairContacts]="affairContacts"
  [appKey]="appKey">
</app-affair-show-contacts>
<app-affair-show-meetings
  *ngIf="affair"
  [affair]="affair"
  [class.d-none]="activeTab !== 'MEETINGS'">
</app-affair-show-meetings>
<app-affair-show-messages
  *ngIf="affair"
  [affair]="affair"
  [class.d-none]="activeTab !== 'MESSAGES'">
</app-affair-show-messages>
<app-affair-show-identity-sheet
  *ngIf="affair"
  [affair]="affair"
  [class.d-none]="activeTab !== 'IDENTITY_SHEET'">
</app-affair-show-identity-sheet>
<app-affair-show-shuttle-sheets
  *ngIf="affair"
  [affair]="affair"
  [class.d-none]="activeTab !== 'SHUTTLES_SHEETS'">
</app-affair-show-shuttle-sheets>
<app-affair-show-invoices
  *ngIf="affair"
  [class.d-none]="activeTab !== 'INVOICING'"
  [affair]="affair"
  [payers]="payersByAgencySelect"
  [mlAgencies]="mlAgenciesSelect">
</app-affair-show-invoices>
<app-affair-show-quotations
  *ngIf="affair"
  [class.d-none]="activeTab !== 'QUOTATIONS'"
  [affair]="affair">
</app-affair-show-quotations>
<app-affair-show-mails
  [affair]="affair"
  *ngIf="affair"
  [class.d-none]="activeTab !== 'MAILS'">
</app-affair-show-mails>
<app-affair-show-ynergie
  [affair]="affair"
  *ngIf="affair"
  [class.d-none]="activeTab !== 'YNERGIE'">
</app-affair-show-ynergie>
<app-affair-show-history
  *ngIf="affair"
  [class.d-none]="activeTab !== 'HISTORY'"
  [affair]="affair">
</app-affair-show-history>
