<div
  class="container"
  *ngIf="_boxed; else basicLayoutBlock">
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</div>
<ng-template #basicLayoutBlock>
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</ng-template>
<!-- YOUR LAYOUT CONTENT GOES INSIDE HERE -->
<ng-template #contentTpl>
  <!-- PAGE SIDEBAR -->
  <pg-sidebar>
    <ng-template #sideBarHeader>
      <img
        src="../../assets/img/logo-sidebar.png"
        alt="logo"
        class="brand"
        pgRetina
        src1x="../../assets/img/logo-sidebar.png"
        src2x="assets/img/logo_white_2x.png"
        width="140"
        height="50">
      <div class="sidebar-header-controls">
        <button
          type="button"
          class="btn btn-icon-link invert d-lg-inline-block d-xlg-inline-block d-md-inline-block d-sm-none d-none"
          data-toggle-pin="sidebar"
          (click)="toggleMenuPin()">
          <i class="pg-icon"></i>
        </button>
      </div>
    </ng-template>
    <ng-template #menuItems>
      <pg-menu-items [Items]="menuLinks"></pg-menu-items>
    </ng-template>
  </pg-sidebar>
  <!-- PAGE CONTAINER -->
  <page-container>
    <pg-header [boxed]="_boxed">
      <!-- START MOBILE SIDEBAR TOGGLE -->
      <a
        href="javascript:void(0);"
        class="btn-icon-link toggle-sidebar d-lg-none"
        (click)="toggleMobileSidebar()">
        <i class="pg-icon">menu</i>
      </a>
      <!-- END MOBILE SIDEBAR TOGGLE -->
      <div class>
        <!-- START EMAIL MOBILE TOGGLE -->
        <a
          href="javascript:void(0);"
          class="toggle-secondary-sidebar align-items-center"
          (click)="toggleSecondarySideBar()"
          *ngIf="_layoutOption === 'email'">
          <span class="d-flex align-items-center">
            Inbox
            <span class="text-info">(12)</span>
            <span class="pg-icon">drop_down</span>
          </span>
        </a>
        <!-- END EMAIL MOBILE TOGGLE -->
        <div
          class="brand inline"
          [class.d-none]="_layoutOption === 'email'">
          <img
            src="../../assets/img/logo.png"
            alt="logo"
            pgRetina
            src1x="../../assets/img/logo.png"
            src2x="../../assets/img/logo.png"
            width="135"
            height="50">
        </div>
        <a
          href="javascript:void(0)"
          class="search-link d-lg-inline-block d-none"
          (click)="openSearch($event)">
          <i class="fa fa-search"></i>
          <span class="bold">Rechercher</span>
        </a>
      </div>
      <h4
        *ngIf="!isProductionMode()"
        class="text-danger text-center">
        <i class="fas fa-exclamation-triangle"></i>
        Version de développement
      </h4>
      <div class="d-flex align-items-center">
        <div class="pull-right d-lg-block d-none">
          <ng-container *ngFor="let marketType of selectedMarketsTypes; let i = index">
            <span
              class="thumbnail-wrapper d32 circular inline"
              [ngClass]="{'ml-1': i > 0}"
              [title]="marketType.name"
              (click)="onSelectMarketTypeClicked()">
              <div class="d32 circle circular-lightgrey">
                <i class="tll tll-18 fa-{{marketType.tllIcon}}"></i>
              </div>
            </span>
          </ng-container>
        </div>
        <div class="header-vertical-separator"></div>
        <div
          class="dropdown pull-right d-lg-block d-none"
          dropdown>
          <button
            class="profile-dropdown-toggle"
            type="button"
            dropdownToggle
            id="profileDropdown"
            aria-label="profile dropdown">
            <span class="thumbnail-wrapper d32 circular inline">
              <div
                class="d32 circle"
                style="background-color: lightgrey;">
                <span>
                  <b>{{ this.userInfos.initials}}</b>
                </span>
              </div>
            </span>
          </button>
          <div
            class="dropdown-menu dropdown-menu-right profile-dropdown"
            *dropdownMenu
            role="menu">
            <a
              href="#"
              class="dropdown-item"
              (click)="logout()">
              Se déconnecter
            </a>
          </div>
        </div>
      </div>
    </pg-header>
    <div class="page-content-wrapper {{_pageContainerClass}}">
      <!-- START PAGE CONTENT -->
      <div class="content {{_contentClass}}">
        <router-outlet></router-outlet>
      </div>
      <!-- END PAGE CONTENT -->
    </div>
    <global-search-overlay></global-search-overlay>
  </page-container>
  <!-- QUICKVIEW -->
</ng-template>
